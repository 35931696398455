<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:toolbar-buttons>
      <ButtonLink
        :href="'/admin/tasks/list/?context=' + provider?.gid"
        icon="mdi-server"
        text="Tasks"
      />
      <ButtonAction
        :action="syncInventories"
        color="orange darken-1"
        icon="mdi-database-sync"
        text="Rafraîchir stocks"
      />
      <ButtonAction
        :action="() => modal.setup.opened = true"
        color="orange darken-1"
        icon="mdi-cog-transfer"
        text="Vérifier setup"
      />
    </template>

    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-table-account
      </v-icon>
      Edition de provider :
      <span v-if="provider?.id">
        {{ provider.id }}
      </span>
    </template>
    
    <template v-slot:content>
      <v-card
        v-if="mounted"
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  Détail
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      sm="12"
                    >
                      <v-simple-table class="lh_item-return__details">
                        <tbody>
                          <tr>
                            <td>
                              Nom
                            </td>
                            <td>
                              {{ provider.name }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Source
                            </td>
                            <td>
                              {{ $t('views.external_catalog.classifications.enum.sources.' + provider.source) }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Full synchro
                            </td>
                            <td>
                              {{ provider.full_synchro ? 'oui' : 'non' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Store par défaut
                            </td>
                            <td>
                              <StoreLink :store="provider.store" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Date de creation
                            </td>
                            <td>
                              {{ provider.created_at | date }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <v-card>
                <v-card-title>
                  Synchroniser
                </v-card-title>
                <v-card-text>
                  <SyncFromCSV
                    v-if="provider.source==='csv-default'"
                    :provider="provider.id"
                  />
                  <SyncFromShopify
                    v-else-if="provider.source==='shopify'"
                    :provider="provider.id"
                  />
                  <div v-else>
                    Source non supportée
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <SetupModal
        :opened.sync="modal.setup.opened"
        :provider="provider"
      />
    </template>
  </Page>
</template>

<script>
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ButtonLink from '@/components/base/Toolbar/ButtonLink.vue'
import Page from '@/components/core/Page.vue'
import SetupModal from '@/views/ExternalCatalog/Providers/Modal/SetupModal.vue'
import snackbarMixin from '@/mixins/snackbar'
import StoreLink from '@/components/base/Link/Store.vue'
import SyncFromCSV from '@/views/ExternalCatalog/Providers/SyncFromCSV.vue'
import SyncFromShopify from '@/views/ExternalCatalog/Providers/SyncFromShopify.vue'
import taskMixin from '@/mixins/task'

export default {
  name: 'ExternalCatalogProvidersEdit',
  components: {
    SetupModal,
    ButtonAction,
    ButtonLink,
    Page,
    StoreLink,
    SyncFromCSV,
    SyncFromShopify,
  },
  mixins: [snackbarMixin, taskMixin],
  data() {
    return {
      taskId: null,
      providerId: this.$route.params.id,
      mounted: false,
      request: null,
      loading: false,
      provider: {},
      requestTask: null,
      resultTask: {
        items: [],
        count: 0,
        loading: false,
      },
      modal: {
        setup: {
          opened: false,
        },
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.loading = true
      this.$axios.get('external_catalog/providers/' + this.providerId, { cancelToken: axiosSource.token })
        .then((response) => {
          this.provider = response.data
        })
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.external_catalog.provider.message.failed', { error: error_message }))
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    async syncInventories () {
      try {
        const response = await this.$axios.post('external_catalog/providers/' + this.providerId + '/sync_inventories')

        this.taskCreated(response.data.id)
      } catch (error) {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
      } finally {
        this.file = null
      }
    },
  },
}
</script>
